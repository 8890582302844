var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-y-auto", staticStyle: { "max-height": "520px" } },
    [
      _vm._l(_vm.contacts, function(contact, index) {
        return [
          _c(
            "div",
            { key: index, staticClass: "d-flex" },
            [
              _c("contact-view", {
                attrs: { contact: contact, exclude: [], color: _vm.color }
              }),
              _c("v-spacer"),
              _c("psi-action-drop-down", {
                attrs: {
                  "btn-class": "ma-3 " + _vm.color,
                  heading: "Contact Actions Menu",
                  subheading: contact.name,
                  items: _vm.items
                },
                on: { action: _vm.itemClicked }
              })
            ],
            1
          ),
          _c("v-divider", { key: "divider-" + index })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }